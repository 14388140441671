import type { GenericAsyncThunk, BoundAsyncThunk } from './types';
import type { Reducer, Action, UnknownAction, ActionCreator } from '@reduxjs/toolkit';
import type { PersistConfig } from 'redux-persist';
import { bindActionCreators, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import config from '../../../config';
import { bindAsyncActionCreators } from './bind-async-action-creators';

/**
 * Creates a Redux store with persistence and bound actions.
 *
 * @template S State object type.
 * @template A Action type.
 * @template Actions Type of synchronous actions.
 * @template AsyncThunk Type of asynchronous thunks.
 * @template AsyncActions Type of object containing asynchronous actions.
 * @template Selectors Type of selectors.
 *
 * @param reducer Reducer function or object.
 * @param actions Object containing action creators.
 * @param selectors Object containing selector functions.
 * @param asyncActions Object containing asynchronous action creators.
 * @param onInitialize Function to execute after store initialization.
 *
 * @returns The configured store and related utilities.
 */
const createStore = <
  S = unknown,
  A extends Action = UnknownAction,
  Actions extends { readonly [key: string]: ActionCreator<UnknownAction> } = {
    readonly [key: string]: ActionCreator<A>;
  },
  AsyncThunk extends GenericAsyncThunk = GenericAsyncThunk,
  AsyncActions extends { readonly [key: string]: GenericAsyncThunk } = { readonly [key: string]: AsyncThunk },
  Selectors extends Record<string, unknown> = Record<string, unknown>
>(
    reducer: Reducer<S, A>,
    actions: Actions,
    selectors: Selectors,
    asyncActions?: AsyncActions,
    onInitialize?: ({
      actions,
    }: {
    actions: Actions & { [key in keyof AsyncActions]: BoundAsyncThunk<AsyncActions[key]> };
  }) => void,
  ) => {
  const persistConfig: PersistConfig<S> = {
    key      : 'global',
    whitelist: ['planes'],
    storage  : sessionStorage,
    version  : 7,
  };
  const persistedReducer = typeof reducer === 'function' ? persistReducer(persistConfig, reducer) : reducer;
  const store = configureStore({
    devTools  : config.dev_tools_enabled ? { trace: true, traceLimit: 50 } : false,
    reducer   : persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck   : true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        thunk             : true,
        actionCreatorCheck: true,
      }),
    enhancers: defaultEnhancers => defaultEnhancers({ autoBatch: true }),
  });

  const boundActions = bindActionCreators(actions, store.dispatch);
  const boundAsyncActions = asyncActions
    ? bindAsyncActionCreators(asyncActions, store.dispatch, store.getState, {})
    : {};
  const combinedActions = { ...boundActions, ...boundAsyncActions } as Actions & {
    [key in keyof AsyncActions]: BoundAsyncThunk<AsyncActions[key]>;
  };
  const persistor = persistStore(store, {}, () => {
    onInitialize?.({ actions: combinedActions });
  });

  return {
    dispatch      : store.dispatch,
    getState      : store.getState,
    subscribe     : store.subscribe,
    replaceReducer: store.replaceReducer,
    actions       : combinedActions,
    selectors,
    persistor,
  };
};

export { createStore };
