import type ModuleConfig from '../../core/module-config';
import type { ModuleConfigKey } from '../../core/types';
import { LoggerClient } from '..';
import ModuleConfigManager from './config';
import ModuleEventManager from './event';

/**
 * ModuleClient is the wrapper around any of the remote module related ecosystem classes/services.
 * It serves as the orchestrator for managing and interacting with all the configured remote
 * modules within Platform. It acts as central interface for interacting with the remote modules.
 */
class ModuleClient {
  #logger = LoggerClient.createNamedLogger('ModuleClient');

  /**
   * Gets the ModuleEventManager instance.
   */
  get Events() {
    return ModuleEventManager;
  }

  /**
   * @param moduleConfigKey
   * @returns The module configuration for the given planeId.
   */
  getModuleConfig(moduleConfigKey: ModuleConfigKey) {
    return ModuleConfigManager.getModuleConfig(moduleConfigKey);
  }

  /**
   * Sets the configured module configuration into the stored module configurations and flushes any
   * events that were waiting for the module to be registered.
   * @param moduleConfig
   */
  registerModuleConfig(moduleConfig: ModuleConfig) {
    this.#logger.log(`Module configuration received from ${moduleConfig.key}, registering...`, moduleConfig);
    ModuleConfigManager.setModuleConfig(moduleConfig);
    ModuleEventManager.flushEvents(moduleConfig.key);
  }

  /**
   * Clears all the module configs from the config manager.
   */
  clearConfigs() {
    ModuleConfigManager.clearConfigs();
  }
}

const moduleClient = new ModuleClient();
export default moduleClient;
