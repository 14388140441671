/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
export type ClaimsResponse = {
  name: string;
  givenName: string;
  surname: string;
  emailAddress: string;
  issuingAuthority: string;
  id: string;
  status: string;
  identifyingParty: string;
  subscriptionId: string;
  productId: string;
  seatId: string;
  subscriptionName: string;
  subscriptionEndDate: string;
  subscriptionModel: string;
  isSubscriptionLA: boolean;
  deployUrl: string;
  features: string[];
  roles: string[];
}

class AuthenticationClient {
  static login() { /* NO-OP */ }
  static logout() { /* NO-OP */ }
  static openManage() { /* NO-OP */ }
  static async getClaims(): Promise<ClaimsResponse> {
    return {} as ClaimsResponse;
  }
  /**
   * @throws
   * @comment Weird JSDoc circular reference here, avoiding by just defining a var
   * @param getPlatformCoreState
   */
  static async areCurrentClaimsValid(getPlatformCoreState: () => unknown): Promise<boolean> {
    return true;
  }
}

export default AuthenticationClient;
