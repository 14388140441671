import type { PlaneId } from '../../store/reducers/planes';
import type { ModuleConfigKey } from '../../types';
import { LoggerClient } from '../../../clients';
import BrowserHistoryClient from '../../../clients/browser-history';
import config from '../../../config';
import { ROOT_MODULE_KEY } from '../../constants';
import MODULES from '../../modules';
import { platformStore, useSelector } from '../../store';

class RoutingService {
  #logger = LoggerClient.createNamedLogger('RoutingService');

  get currentModuleKey() {
    return platformStore.selectors.currentModuleKey(platformStore.getState());
  }

  get currentPlaneId() {
    return platformStore.selectors.currentPlaneId(platformStore.getState());
  }

  get currentModule() {
    return platformStore.selectors.currentModule(platformStore.getState());
  }

  get currentPlane() {
    return platformStore.selectors.currentPlane(platformStore.getState());
  }

  /**
   * Selector hook for the current module key.
   * @returns The current module key.
   */
  useCurrentModuleKey() {
    return useSelector(platformStore.selectors.currentModuleKey);
  }

  /**
   * Selector hook for the current module.
   * @returns The current module.
   */
  useCurrentModule() {
    return useSelector(platformStore.selectors.currentModule);
  }

  /**
   * Selector hook for the current plane id.
   * @returns The current plane id.
   */
  useCurrentPlaneId() {
    return useSelector(platformStore.selectors.currentPlaneId);
  }

  /**
   * Selector hook for the current plane.
   * @returns The current plane.
   */
  useCurrentPlane() {
    return useSelector(platformStore.selectors.currentPlane);
  }

  /**
   * Sets the layout state to the given plane.
   * @param planeId - The current plane id.
   */
  toPlane(
    planeId: PlaneId,
    {
      history = true,
      replace = false,
    }: {
      history?: boolean;
      replace?: boolean;
    } = {},
  ) {

    const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
    if (!plane) {
      this.#logger.error(`toPlane: plane ${planeId} not found, navigating to home`);
      this.toModule(this.currentModuleKey, { history: true, replace: true });
      return;
    }

    if (planeId === this.currentPlaneId) {
      return;
    }

    platformStore.actions.setPlaneLoading(plane.id, true);
    platformStore.actions.setCurrentLayout({
      currentModuleKey: plane.module,
      currentPlaneId  : plane.id,
    });

    if (history) {
      BrowserHistoryClient.updateBrowserHistory(
        {
          currentModuleKey: plane.module,
          currentPlaneId  : plane.id,
        },
        { replace },
      );
    }
  }

  /**
   * Sets the layout state to the given module.
   * @param moduleKey - The current module key.
   * @param options - The options to pass to the browser history update.
   */
  toModule(
    moduleKey?: ModuleConfigKey,
    {
      history = true,
      replace = false,
    }: {
      history?: boolean;
      replace?: boolean;
    } = {},
  ) {
    // Determine the module to navigate to based on the order of precedence:
    // provided module key > current module key > root module key.
    const module = MODULES[moduleKey as ModuleConfigKey] ?? this.currentModule ?? MODULES[ROOT_MODULE_KEY];
    if (!module) {
      this.#logger.error('toModule: module not found');
      return;
    }

    const currentModuleKey = config.feature_flag_msso_root ? ROOT_MODULE_KEY : module?.key || ROOT_MODULE_KEY;
    platformStore.actions.setCurrentLayout({
      currentModuleKey,
      currentPlaneId: undefined,
    });

    if (history) {
      BrowserHistoryClient.updateBrowserHistory(
        {
          currentModuleKey,
          currentPlaneId: undefined,
        },
        { replace },
      );
    }
  }
}

export default RoutingService;
