import type { ModuleCardConfig } from '../../types';
import config from '../../../config';
import { RemoteWorkspaceOnline, loadRemoteWorkspaceOnline } from './remote';

const CONFIG = {
  key            : 'WSO',
  title          : 'wso',
  registeredTitle: 'wsoRegistered',
  icon           : 'WorkspaceIcon',
  card           : {
    title      : 'wsoRegistered',
    description: 'wsoDescription',
    tagline    : 'qualityProject',
    icon       : 'WorkspaceIcon',
    heroIcon   : 'WorkspaceHeroIcon',
  } as ModuleCardConfig,
  preloadRemoteModule: loadRemoteWorkspaceOnline,
  remoteModule       : RemoteWorkspaceOnline,
  storage            : {
    // The default project name is used when the user creates a new plane.
    defaultProjectName: 'wsoDefaultProject',
    // The default extension is used when the user creates a new plane.
    defaultExtension  : '.wspx',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState : {},
    // The file extensions that are allowed to be uploaded.
    filter            : ['.wstx', '.wspx', ...(config.feature_flag_ui_v2 ? ['.qcpx'] : [])],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter     : [],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter : [],
  },
} as const;

export default CONFIG;
