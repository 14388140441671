import type { ModuleCardConfig } from '../../types';
import { RemoteReportingTool, loadRemoteReportingTool } from './remote';

const CONFIG = {
  key  : 'REPORTING_TOOL',
  title: 'reportingtool',
  icon : 'MinitabConnectIcon',
  card : {
    title      : 'reportingtool',
    description: 'reportingToolDescription',
    tagline    : 'report',
    icon       : 'MinitabConnectIcon',
    heroIcon   : 'ReportingToolHeroIcon',
  } as ModuleCardConfig,
  preloadRemoteModule: loadRemoteReportingTool,
  remoteModule       : RemoteReportingTool,
  storage            : {
    // The default project name is used when the user creates a new plane.
    defaultProjectName: 'reportingToolDefaultProject',
    // The default extension is used when the user creates a new plane.
    defaultExtension  : '.mrpt',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState : { isDirty: false },
    // The file extensions that are allowed to be uploaded.
    filter            : ['.mrpt'],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter     : ['.mtw', '.mwx'],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter : [],
  },
} as const;

export default CONFIG;
