import { Dialog, useDidMount, GlobalStyles } from '@mtb/ui';
import { v4 as uuid } from 'uuid';

const portalId = uuid();

export function PickerDialog({ service, open, onClose, ...other }) {
  useDidMount(() => {
    (async () => {
      const didPickItems = await service?.openPicker({ portalId });
      onClose(didPickItems);
    })();
  });

  return (
    <>
      <GlobalStyles
        styles={() => ({
          '.picker-dialog-bg': {
            display: 'none !important',
          },
        })} />
      <Dialog
        data-testid={`portal-dialog-${portalId}`}
        disableEscapeKeyDown
        open={open}
        SurfaceProps={{
          sx: { position: 'unset' },
          id: portalId,
        }}
        {...other} />
    </>
  );
}

export default PickerDialog;
