import type { ModuleCardConfig } from '../../types';
import { RemoteMinitabOnline, loadRemoteMinitabOnline } from './remote';

const CONFIG = {
  key            : 'MSSO',
  title          : 'msso',
  registeredTitle: 'mssoRegistered',
  icon           : 'MinitabOnlineIcon',
  card           : {
    title      : 'mssoRegistered',
    description: 'mssoDescription',
    icon       : 'MinitabOnlineIcon',
    heroIcon   : 'MinitabOnlineHeroIcon',
    tagline    : 'analytics',
  } as ModuleCardConfig,
  preloadRemoteModule: loadRemoteMinitabOnline,
  remoteModule       : RemoteMinitabOnline,
  storage            : {
    // The default project name is used when the user creates a new plane.
    defaultProjectName: 'mssoDefaultProject',
    // The default extension is used when the user creates a new plane.
    defaultExtension  : '.mpx',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState : { isNew: false },
    // The file extensions that are allowed to be uploaded.
    filter            : ['.mpx', '.mpj'],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter     : ['.csv', '.dat', '.mac', '.mtb', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter : ['.csv', '.dat', '.mac', '.mtb', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
  },
} as const;

export default CONFIG;
