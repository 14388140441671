/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Plane } from '../store/reducers/planes';
import type { CloudStoragePassthroughItem, CloudStorageProjectItem } from '@mtb/cloud-storage/types';

/**
 * Represents the events configuration for a platform remote module.
 */
class ModuleConfigEvents {
  onPulse = (_plane: Plane) => Promise.resolve();
  onCleanup = (_plane: Plane) => Promise.resolve();
  onClose = (_plane: Plane) => Promise.resolve();
  onFlush = (_plane: Plane) => Promise.resolve();
  onOpen = (_plane: Plane, _fileInfo: CloudStoragePassthroughItem | CloudStorageProjectItem) => Promise.resolve();
}

export default ModuleConfigEvents;
