import type { SetCurrentLayoutPayload } from './types';
import type { ModuleConfigKey } from '../../../../types';
import type { PlaneId } from '../../planes';
import type { WritableLayoutState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const setCurrentLayout = {
  reducer: (
    state: WritableLayoutState,
    action: PayloadAction<{ currentPlaneId?: PlaneId; currentModuleKey: ModuleConfigKey }>,
  ) => {
    state.currentModuleKey = action.payload.currentModuleKey;
    state.currentPlaneId = action.payload.currentPlaneId;
  },
  prepare: (payload: SetCurrentLayoutPayload) => {
    return { payload };
  },
};
