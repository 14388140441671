import React, { useState } from 'react';
import { Skeleton, Stack, Typography, CheckedOutOutlinedIcon, useDidMount } from '@mtb/ui';
import { useCloudStorageProject } from '../../hooks';
import { Trans } from '../../services/i18n';

const ICON_SIZE = { height: 24, width: 24 };

/**
 * @param {Object} props - Component props.
 * @param {string} props.projectId - The ID of the project to get the checkout status of.
 * @returns {JSX.Element | undefined} Returns a JSX element displaying the user that currently has the item checked out or undefined if the checkout is unclaimed.
 */
export function CheckoutStatus({ projectId }) {
  const project = useCloudStorageProject(projectId);

  const [loading, setLoading] = useState(true);
  const [checkoutUserName, setCheckoutUserName] = useState(undefined);
  const [isReadonlyStale, setIsReadonlyStale] = useState(false);

  useDidMount(() => {
    let current = true;
    (async () => {
      const checkoutUser = await project.getCheckoutUser();
      if (!current) {
        return;
      }
      if (checkoutUser?.displayName) {
        setCheckoutUserName(checkoutUser.displayName);
      } else {
        setIsReadonlyStale(true);
      }
      setLoading(false);
    })();
    return () => {
      current = false;
    };
  });

  if (isReadonlyStale) {
    return undefined;
  }

  return (
    <Stack
      align="center"
      direction="row"
      gap={0.75}>
      {loading ? (
        <>
          <Skeleton
            sx={ICON_SIZE}
            variant="circular" />
          <Skeleton
            sx={{ flexGrow: 1, flexShrink: 0 }}
            variant="text" />
        </>
      ) : (
        <>
          <CheckedOutOutlinedIcon sx={ICON_SIZE} />
          <Typography>
            <Trans
              components={[
                <Typography
                  sx={{ display: 'inline' }}
                  weight="bold" />,
              ]}
              i18nKey="connection.checkedOutItem"
              values={{ userName: checkoutUserName }} />
          </Typography>
        </>
      )}
    </Stack>
  );
}
