import type { ModuleCardConfig } from '../../types';
import { RemoteDataCenter, loadRemoteDataCenter } from './remote';

const CONFIG = {
  key  : 'DATACENTER',
  title: 'datacenter',
  icon : 'DatabaseIcon',
  card : {
    title      : 'datacenter',
    description: 'dataCenterDescription',
    tagline    : 'dataSource',
    icon       : 'DatabaseIcon',
    heroIcon   : 'DataCenterHeroIcon',
  } as ModuleCardConfig,
  preloadRemoteModule: loadRemoteDataCenter,
  remoteModule       : RemoteDataCenter,
  storage            : {
    // The default project name is used when the user creates a new plane.
    defaultProjectName: 'datacenterDefaultProject',
    // The default extension is used when the user creates a new plane.
    defaultExtension  : '.mdpx',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState : {},
    // The file extensions that are allowed to be uploaded.
    filter            : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter     : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter : ['.csv', '.dat', '.mtw', '.mwx', '.txt', '.xls', '.xlsx'],
  },
} as const;

export default CONFIG;
