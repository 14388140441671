import { ThemeProvider, storage } from '@mtb/ui';
import { v5 } from 'uuid';
import { STORAGE_PROVIDER_KEYS } from '../constants';
import { getI18nStore } from './i18n';

/**
 * Update the login counter in localStorage.
 */
export const updateLoginCounter = () => {
  const loginCounter = storage.localStorage.getItem('mslogin-counter');
  const { count = 0, timestamp } = loginCounter ?? {};
  const within10Min = timestamp && Date.now() - new Date(timestamp).getTime() < 600000;
  const incrementer = within10Min && count <= 5 ? count + 1 : 1;
  storage.localStorage.setItem('mslogin-counter', { count: incrementer, timestamp: Date.now() });
};

/**
 * Get the connection type from the intercepted state.
 * @param {import('@').InterceptedAuthState} state - Intercepted state information.
 * @returns {import('@').StorageProvider|null} - The connection type or null if not recognized.
 */
export const getTypeFromInterceptedState = state => {
  if (state.connectionType === STORAGE_PROVIDER_KEYS.ONE_DRIVE || state.msGraph) {
    return STORAGE_PROVIDER_KEYS.ONE_DRIVE;
  } else if (state.connectionType === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE || state.gDrive) {
    return STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE;
  }
  return null;
};

/**
 * @param {string} elementId
 * @returns {import('react-dom/client').Root}
 */
const createRoot = elementId => {
  if (!document.getElementById(elementId)) {
    const el = document.createElement('div');
    el.id = elementId;
    document.body.appendChild(el);
  }
  try {
    const ReactDOMClient = require('react-dom/client');
    return ReactDOMClient.createRoot(document.getElementById(elementId));
  } catch {
    // We must be React < 18
    const ReactDOM = require('react-dom');
    return {
      render(children) {
        ReactDOM.render(children, document.getElementById(elementId));
      },
      unmount() {
        ReactDOM.unmountComponentAtNode(document.getElementById(elementId));
      },
    };
  }
};

/**
 * @typedef {boolean} ShouldClose
 */

/**
 * @template Props
 * @template {any[]} [Args=[import('@').Simplify<Omit<Props, "onClose"> & { onClose?: (Parameters<Props["onClose"]>) => ShouldClose }>]]
 * @typedef {(t?: import('i18next').TFunction, onClose?: (confirmed: boolean) => void, ...args: Args) => Props} ResolvePropsFn
 */

/**
 * @template {import('react').ComponentType<any>} DialogComponent
 * @template {import('@').PropsOf<DialogComponent>} Props
 * @template {any[]} [Args=[import('@').Simplify<Omit<Props, "onClose"> & { onClose?: (Parameters<Props["onClose"]>) => ShouldClose }>]]
 * @template {ResolvePropsFn<Props, Args>} Resolver
 * @param {DialogComponent} Component
 * @param {Resolver} [resolveProps=Resolver]
 */
const createAgnosticDialog = (Component, resolveProps) => {
  /** @type {null | { resolve: Parameters<Resolver>['1'], args: Args }} */
  let promise = null;
  const elementId = v5(JSON.stringify(resolveProps()), '17c6d213-20cd-40e6-98ce-70511668e5b5');
  /** @type {ReturnType<createRoot>} */
  let root;

  /**
   * @param {boolean} confirmed
   */
  const handleOnClose = confirmed => {
    promise?.resolve(confirmed);
    promise = null;
    root.unmount();
  };

  const render = () => {
    const [t] = getI18nStore();
    root = createRoot(elementId);
    const props = resolveProps(t, handleOnClose, ...promise.args);
    const compoundOnClose = result => {
      if (typeof props?.onClose !== 'function') {
        handleOnClose();
        return;
      }
      if (props.onClose === handleOnClose) {
        props.onClose(result);
        return;
      }
      if (!props.onClose(result)) {
        return;
      }
      handleOnClose();
    };
    root.render(
      <ThemeProvider theme={{}}>
        <Component
          {...props}
          onClose={compoundOnClose} />
      </ThemeProvider>,
    );
  };

  /**
   * @param  {Args} args
   * @returns {Promise<boolean>}
   */
  const confirm = (...args) =>
    new Promise(resolve => {
      promise = { resolve, args };
      render();
    });
  Object.defineProperty(confirm, 'name', { value: Component.displayName ?? Component.name });

  return confirm;
};

export const createAgnosticSaveToDialog = () => {
  const { SaveToDialog } = require('../dialogs/SaveToDialog/component');
  return createAgnosticDialog(SaveToDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { id: 'save-to', ...props };
    }
    return {
      open: true,
      onClose,
      ...props,
    };
  });
};

/**
 * @param {import('@').CloudStorageProjectConnectionStatus} type
 */
export const createAgnosticConnectionDialog = type => {
  const { ConnectionDialog } = require('../dialogs/ConnectionDialog/component');
  return createAgnosticDialog(ConnectionDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { type, ...props };
    }
    return {
      type,
      open: true,
      onClose,
      ...props,
    };
  });
};

/**
 * @param {string} title
 * @param {string} message
 */
export const createAgnosticAlertDialog = (title, message) => {
  const { AlertDialog } = require('../dialogs/AlertDialog/component');
  return createAgnosticDialog(AlertDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { title, message, ...props };
    }
    return {
      message: t(message),
      title  : t(title),
      open   : true,
      onClose,
      ...props,
    };
  });
};

/**
 * @param {string} title
 * @param {string} message
 */
export const createAgnosticConfirmDialog = (title, message, cancelButtonText, confirmButtonText) => {
  const { ConfirmDialog } = require('../dialogs/ConfirmDialog/component');
  return createAgnosticDialog(ConfirmDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { title, message, ...props };
    }
    return {
      title  : t(title),
      message: t(message),
      ...(cancelButtonText && { cancelButtonText: t(cancelButtonText) }),
      ...(confirmButtonText && { confirmButtonText: t(confirmButtonText) }),
      open   : true,
      onClose,
      ...props,
    };
  });
};

export const createAgnosticOpenInDesktopDialog = () => {
  const { OpenInDesktopDialog } = require('../dialogs/OpenInDesktopDialog/component');
  return createAgnosticDialog(OpenInDesktopDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { ...props };
    }
    return {
      open: true,
      onClose,
      ...props,
    };
  });
};

export const createAgnosticReopenProjectFailedDialog = () => {
  const { ReopenProjectFailedDialog } = require('../dialogs/ReopenProjectFailedDialog/component');
  return createAgnosticDialog(ReopenProjectFailedDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { ...props };
    }
    return {
      open: true,
      onClose,
      ...props,
    };
  });
};

export const createAgnosticPickerDialog = service => {
  const { PickerDialog } = require('../dialogs/PickerDialog/component');
  return createAgnosticDialog(PickerDialog, (t, onClose, props) => {
    if (!t && !onClose) {
      return { ...props };
    }
    return {
      open: true,
      onClose,
      service,
      ...props,
    };
  });
};
