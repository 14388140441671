import type { ModuleCardConfig } from '../../types';
import { RemoteBrainstorm, loadRemoteBrainstorm } from './remote';

const CONFIG = {
  key  : 'BRAINSTORM',
  title: 'brainstorm',
  icon : 'BrainstormIcon',
  card : {
    title      : 'brainstorm',
    description: 'brainstormDescription',
    tagline    : 'ideas',
    icon       : 'BrainstormIcon',
    heroIcon   : 'BrainstormHeroIcon',
  } as ModuleCardConfig,
  preloadRemoteModule: loadRemoteBrainstorm,
  remoteModule       : RemoteBrainstorm,
  storage            : {
    // The default extension is used when the user creates a new plane.
    defaultExtension : '.mbpx',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState: {},
    // The file extensions that are allowed to be uploaded.
    filter           : ['.mbtx', '.mbpx'],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter    : [],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter: [],
  },
} as const;

export default CONFIG;
