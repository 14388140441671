import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  claims: createSelector([state => state.user.claims], claims => ({
    id       : claims.id,
    name     : claims.name,
    firstname: claims.firstname,
    lastname : claims.lastname,
    email    : claims.email,
    roles    : claims.roles,
    features : claims.features,
    valid    : claims.valid,
  })),
  settings: createSelector(
    [state => state.user.settings],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    settings => ({ ...settings }),
  ),
  locale: createSelector(
    [state => state.user.settings.locale],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    locale => ({ ...locale }),
  ),
};

export default selectors;
