import type { Config } from './config';

const PLATFORM_SESSION_CONFIG = 'platform-session-config' as const;

/**
 * Sets the configuration overrides in session storage.
 * Which will then be used by the config proxy anytime a config value is accessed.
 * @param overrides - The configuration overrides to set.
 */
export function setSessionConfig(overrides: Partial<Config>) {
  const overridesJSON = JSON.stringify({ ...getSessionConfig(), ...overrides });
  sessionStorage.setItem(PLATFORM_SESSION_CONFIG, overridesJSON);
}

/**
 * @returns The configuration overrides from session storage.
 */
export function getSessionConfig(): Partial<Config> {
  const storedConfig = sessionStorage.getItem(PLATFORM_SESSION_CONFIG);
  return storedConfig ? JSON.parse(storedConfig) : {};
}
