const CONFIG = {
  key                : 'PLATFORM',
  title              : 'platform',
  icon               : 'MinitabIcon',
  preloadRemoteModule: () => Promise.resolve(),
  remoteModule       : null,
  storage            : {
    // The default project name is used when the user creates a new plane.
    defaultProjectName: '',
    // The default extension is used when the user creates a new plane.
    defaultExtension  : '',
    // The default plane props are used when the user creates a new plane.
    defaultPlaneState : {},
    // The file extensions that are allowed to be uploaded.
    filter            : [],
    // The file extensions that are allowed to be opened in the app rail variant of the storage explorer
    appRailFilter     : [],
    // The file extensions that allow the remote modules to handle opening operations in a custom manner.
    passthroughFilter : [],
  },
} as const;

export default CONFIG;
