import type { Modules } from '../modules';
import type { Keys } from '../types';
import config from '../../config';

type RemoteUrlModuleKeys = Keys<Omit<Required<Modules>, 'PLATFORM'>>;
type DefaultRemoteUrlConfigs = Record<RemoteUrlModuleKeys, string>;
type RemoteUrlConfigs = DefaultRemoteUrlConfigs & { __defaults__: DefaultRemoteUrlConfigs };

const wsoModuleUrlConfig = process.env.REACT_APP_WSO_URL || `${window.location.origin}/cdn/workspaceremotemodule`;
const reportingToolModuleUrlConfig = `${window.location.origin}/cdn/reportingtoolremotemodule`;

// TODO: Investigate if this can come from the module configs so we don't have this area to update and maintain.
const defaultRemoteModuleUrlConfigs: DefaultRemoteUrlConfigs = {
  BRAINSTORM    : wsoModuleUrlConfig,
  WSO           : wsoModuleUrlConfig,
  MSSO          : process.env.REACT_APP_MSSO_URL || `${window.location.origin}/cdn/msso`,
  DATACENTER    : process.env.REACT_APP_DATACENTER_URL || `${window.location.origin}/cdn/datacenterremotemodule`,
  REPORTING_TOOL: reportingToolModuleUrlConfig,
};

const remoteModuleUrlConfigs: RemoteUrlConfigs = new Proxy(
  { ...defaultRemoteModuleUrlConfigs, __defaults__: { ...defaultRemoteModuleUrlConfigs } },
  {
    get: (target, property) => {
      if (config.dev_tools_enabled && property === '__defaults__') {
        return { ...defaultRemoteModuleUrlConfigs };
      }
      const storedRemoteModuleUrlConfigs =
        config.dev_tools_enabled && sessionStorage.getItem('platform-remote-module-url-configs')
          ? JSON.parse(sessionStorage.getItem('platform-remote-module-url-configs') ?? '{}')
          : {};
      return Reflect.get({ ...defaultRemoteModuleUrlConfigs, ...storedRemoteModuleUrlConfigs }, property);
    },
    set: (target, property, value) => {
      if (!config.dev_tools_enabled) {
        return false;
      }
      if (typeof property !== 'string') {
        return Reflect.set(target, property, value);
      }
      const result = Reflect.set(target, property, value);
      if (result) {
        sessionStorage.setItem('platform-remote-module-url-configs', JSON.stringify(target));
      }
      return result;
    },
  },
);

export default remoteModuleUrlConfigs;
