import config from '../../config';
import BRAINSTORM from './brainstorm';
import DATACENTER from './datacenter';
import DISCOVER from './discover';
import LEARNING_CENTER from './learning-center';
import MSSO from './msso';
import PLATFORM from './platform';
import REPORTING_TOOL from './reportingtool';
import WSO from './wso';

export type Modules = {
  PLATFORM: typeof PLATFORM;
  MSSO: typeof MSSO;
  BRAINSTORM?: typeof BRAINSTORM;
  WSO?: typeof WSO;
  DATACENTER?: typeof DATACENTER;
  REPORTING_TOOL?: typeof REPORTING_TOOL;
  // TODO: Add LEARNING_CENTER and DISCOVER after
  // we've cleaned up the types for this.
};

const MODULES = {
  PLATFORM,
  MSSO,
  ...(config.feature_flag_brainstorm && { BRAINSTORM }),
  ...(config.feature_flag_wso && { WSO }),
  ...(config.feature_flag_data_center && { DATACENTER }),
  ...(config.feature_flag_reporting_tool && { REPORTING_TOOL }),
  ...(config.feature_flag_ui_v2 && { DISCOVER }),
  ...(config.feature_flag_ui_v2 && { LEARNING_CENTER }),
};

export default MODULES;
