import type { PlatformCore } from '../core';
import type { Plane } from '../core/store/reducers/planes';
import type { ModuleConfigKey } from '../core/types';
import type { CSSProperties, Theme } from '@mtb/ui/types/types';
import type { MutableRefObject, ReactNode } from 'react';

export type PlatformModuleSetRootStyles = (
  styles:
    | CSSProperties
    | ((params: { theme: Theme; rootRef: MutableRefObject<HTMLDivElement | null> }) => CSSProperties)
) => CSSProperties;

export type PlatformModuleProps = {
  theme: Theme;
  setRootStyles: PlatformModuleSetRootStyles;
  plane: Plane;
  rehydrateState: object;
  setRehydrateState: (state: object) => void;
  children?: ReactNode
};

type GenericModuleConfig = {
  key: string;
  title: string;
  description: string;
  icon: string;
  remoteModule: PlatformModuleProps;
  storage: {
    defaultExtension: string;
    defaultPlaneState: Record<string, unknown>;
    filter: readonly string[];
    appRailFilter: readonly string[];
    passthroughFilter: readonly string[];
  };
};

/**
 * Represents the base configuration for a platform remote module.
 */
export class ConfigBase {
  /**
   * A pointer to the parent instance of Platform Core
   */
  #core: PlatformCore;

  #moduleKey: ModuleConfigKey;

  /**
   * Get the moduleKey of the module.
   */
  get moduleKey() {
    return this.#moduleKey;
  }

  /**
   * Get the module config of the module.
   */
  get module() {
    return this.#core.Modules[this.#moduleKey] as unknown as GenericModuleConfig;
  }

  /**
   * Get the module config of the module.
   */
  get core() {
    return this.#core;
  }

  /**
   * Creates an instance of a ConfigBase.
   * @param moduleKey - The moduleKey of the module.
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  constructor(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    if (!moduleKey) {
      throw new Error('ConfigBase: moduleKey is required.');
    }
    if (!platformCore) {
      throw new Error('ConfigBase: platformCore is required.');
    }

    this.#moduleKey = moduleKey;
    this.#core = platformCore;
  }

  /**
   * Creates an instance of a ConfigBase.
   * @param moduleKey
   * @param platformCore - A pointer to the parent instance of Platform Core
   */
  static Create(moduleKey: ModuleConfigKey, platformCore: PlatformCore) {
    return new ConfigBase(moduleKey, platformCore);
  }
}

export default ConfigBase;
