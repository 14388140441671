/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ModuleConfigKey } from '../../../types';
import MODULES from '../../../modules';
import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  layout: createSelector(
    [state => state.layout],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    layout => ({ ...layout }),
  ),
  currentModuleKey: createSelector([state => state.layout], layout => layout.currentModuleKey),
  currentModule   : createSelector(
    [state => state.layout],
    layout => MODULES[layout.currentModuleKey as ModuleConfigKey],
  ),
  currentPlaneId: createSelector([state => state.layout], layout => layout.currentPlaneId),
  currentPlane  : createSelector([state => state.layout, state => state.planes], (layout, planes) =>
    layout.currentPlaneId ? planes[layout.currentPlaneId] : undefined,
  ),
};

export default selectors;
