import {
  GoogleDriveIcon,
  MicrosoftOneDriveIcon,
  FolderOpenIcon,
  WorkspaceProjectIcon,
  WorkspaceTemplateIcon,
  FolderIcon,
  BrainstormProjectIcon,
  BrainstormTemplateIcon,
  MinitabOnlineProjectIcon,
  MinitabOnlineWorksheetIcon,
  colors,
} from '@mtb/ui';

/**
 * Keys representing supported storage providers.
 */
export const STORAGE_PROVIDER_KEYS = /** @type {const} */ ({
  GOOGLE_DRIVE: 'gdrive',
  ONE_DRIVE   : 'msgraph',
  LOCAL       : 'local',
});
/** @typedef {typeof STORAGE_PROVIDER_KEYS} StorageProviderKeys */

/**
 * Objects representing supported storage providers.
 */
export const STORAGE_PROVIDERS = /** @type {const} */ ({
  [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: {
    key : STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
    name: 'connection.googleDrive',
    icon: GoogleDriveIcon,
  },
  [STORAGE_PROVIDER_KEYS.ONE_DRIVE]: {
    key : STORAGE_PROVIDER_KEYS.ONE_DRIVE,
    name: 'connection.oneDrive',
    icon: MicrosoftOneDriveIcon,
  },
  [STORAGE_PROVIDER_KEYS.LOCAL]: {
    key : STORAGE_PROVIDER_KEYS.LOCAL,
    name: 'connection.openLocal',
    icon: FolderOpenIcon,
  },
});
/** @typedef {typeof STORAGE_PROVIDERS} StorageProviders */

/**
 * An array of supported storage providers.
 * @type {import('@').StorageProvider[]}
 */
export const STORAGE_PROVIDERS_ARRAY = Object.values(STORAGE_PROVIDERS);

/**
 * The default list of storage providers to be used if not explicitly specified.
 * @type {import('@').StorageProviderKey[]}
 */
export const DEFAULT_PROVIDERS = Object.values(STORAGE_PROVIDER_KEYS);

/**
 * The default maximum size for a file.
 * @type {number}
 * @default Infinity - No limit
 */
export const DEFAULT_MAX_SIZE = Infinity;

/**
 * The default filter used to filter file extensions in the storage explorer.
 * @type {string[]}
 * @default []
 */
export const DEFAULT_FILTER = [];

/**
 * The local storage key for cloud storage information.
 * @type {string}
 */
export const CLOUD_STORAGE_KEY = 'mtb-cloud-storage';

/**
 * The local storage key for cloud storage projects.
 * @type {string}
 */
export const CLOUD_STORAGE_PROJECTS_KEY = 'mtb-cloud-storage-projects';

/**
 * Variables related to Google Drive.
 */
export const GOOGLE_DRIVE = /** @type {const} */ {
  /**
   * The storage key for Google Drive.
   * @type {string}
   */
  STORAGE_KEY: `${STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE}-a88f7d7b-6517-4291-b9de-cb59f9937040`,
  /**
   * The required scopes for Google Drive authentication.
   * @type {string}
   */
  SCOPES     : [
    'https://www.googleapis.com/auth/drive.file',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/drive.install',
  ].join(' '),
  /**
   * The source URL for the Google Drive API.
   * @type {string}
   */
  API_SOURCE: 'https://apis.google.com/js/api.js',
};

/**
 * Variables related to OneDrive.
 */
export const ONE_DRIVE = /** @type {const} */ {
  /**
   * The storage key for OneDrive.
   * @type {string}
   */
  STORAGE_KEY: `${STORAGE_PROVIDER_KEYS.ONE_DRIVE}-88e87edb-7023-407e-8235-17c270b3f3ea`,
  /**
   * The required scopes for OneDrive authentication.
   * @type {string}
   */
  SCOPES     : [
    'Files.Read.All',
    'Files.ReadWrite.All',
    'People.Read',
    'Contacts.Read',
    'User.ReadBasic.All',
    'offline_access',
  ].join(' '),
};

/**
 * Variables related to local.
 */
export const LOCAL = /** @type {const} */ {
  /**
   * The storage key for local.
   * @type {string}
   */
  STORAGE_KEY: `${STORAGE_PROVIDER_KEYS.LOCAL}-0e6923f8-1574-4cc8-a16e-df7a809c4fcf`,
};

/**
 * Icons for different file types.
 */
export const FILE_ICONS = /** @type {const} */ ({
  // Folder
  folder: <FolderIcon sx={{ color: colors.orange[400] }} />,
  // Workspace project
  wspx  : <WorkspaceProjectIcon />,
  // Workspace template
  wstx  : <WorkspaceTemplateIcon />,
  // Workspace project - legacy
  qcpx  : <WorkspaceProjectIcon />,
  // Workspace template - legacy
  qctx  : <WorkspaceTemplateIcon />,
  // Brainstorm template
  mbtx  : <BrainstormTemplateIcon />,
  // Brainstorm project
  mbpx  : <BrainstormProjectIcon />,
  // Map template
  mmtx  : <BrainstormTemplateIcon />, // temporarily using brainstorm icon for map
  // Map project
  mmpx  : <BrainstormProjectIcon />, // temporarily using brainstorm icon for map
  // Minitab Online project
  mpx   : <MinitabOnlineProjectIcon />,
  // Minitab Online project
  mpj   : <MinitabOnlineProjectIcon />,
  // Minitab Online worksheet
  mwx   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online worksheet
  mtw   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online worksheet
  csv   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online worksheet
  xlsx  : <MinitabOnlineWorksheetIcon />,
  // Minitab Online worksheet
  dat   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online worksheet
  txt   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online script
  mac   : <MinitabOnlineWorksheetIcon />,
  // Minitab Online script
  mtb   : <MinitabOnlineWorksheetIcon />,
});

export const DESKTOP_APP_SCHEMES = {
  none           : /** @type {const} */ (null),
  minitab_desktop: /** @type {const} */ ('mtb-mss'),
};

/**
 * Desktop App file associations
 * The keys are file extentions, and the values are the request scheme for the
 * associated desktop application.
 */
export const DESKTOP_APP_FILE_ASSOCIATIONS = /** @type {const} */ ({
  // Folder
  folder: DESKTOP_APP_SCHEMES.none,
  // Workspace project
  wspx  : DESKTOP_APP_SCHEMES.none,
  // Workspace template
  wstx  : DESKTOP_APP_SCHEMES.none,
  // Workspace project - legacy
  qcpx  : DESKTOP_APP_SCHEMES.none,
  // Workspace template - legacy
  qctx  : DESKTOP_APP_SCHEMES.none,
  // Brainstorm template
  mbtx  : DESKTOP_APP_SCHEMES.none,
  // Brainstorm project
  mbpx  : DESKTOP_APP_SCHEMES.none,
  // Minitab Online project
  mpx   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online project
  mpj   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  mwx   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  mtw   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  csv   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  xlsx  : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  dat   : DESKTOP_APP_SCHEMES.minitab_desktop,
  // Minitab Online worksheet
  txt   : DESKTOP_APP_SCHEMES.minitab_desktop,
});

/**
 * The auto-save statuses for a cloud storage project.
 */
export const AUTO_SAVE_STATUS = /** @type {const} */ ({
  NONE                  : 'none',
  PAUSE_ON_NEXT_SAVE    : 'pauseOnNextSave',
  TERMINATE_ON_NEXT_SAVE: 'terminateOnNextSave',
  STARTED               : 'started',
});
/** @typedef {typeof AUTO_SAVE_STATUS} AutoSaveStatus */

export const CLOUD_STATUS = /** @type {const} */ ({
  NONE    : 'none',
  READONLY: 'readonly',
  OWNED   : 'owned',
});
/** @typedef {typeof CLOUD_STATUS} CloudStatus */

/**
 * The default auto-save status for a cloud storage project.
 */
export const CONNECTION_STATUS = {
  SAVED       : /** @type {const} */ ('saved'),
  SAVING      : /** @type {const} */ ('saving'),
  NOT_SAVED   : /** @type {const} */ ('notCurrentlySaved'),
  READONLY    : /** @type {const} */ ('readOnly'),
  CHECKING_OUT: /** @type {const} */ ('checkingOut'),
};
/** @typedef {typeof CONNECTION_STATUS} ConnectionStatus */

/**
 * The URL for the cloud storage API.
 */
export const CLOUD_STORAGE_API_URL = /** @type {const} */ ('/api/v1/storage');

/**
 * The default filter used for the storage explorer when using the save to dialog.
 */
export const CHANGE_LOCATION_FILTER = [];

/**
 * The minimum storage space required to interact with the cloud storage API.
 */
export const MINIMUM_STORAGE_SPACE_THRESHOLD = 10 * 1024 * 1024; // 10 MB

/**
 * The status of the cloud storage project.
 */
export const PROJECT_STATUS = /** @type {const} */ ({
  // Project is not connected.
  LOCAL       : 'LOCAL',
  // Project is connected.
  CONNECTED   : 'CONNECTED',
  // Project's connection was lost, due to the provider being changed/removed. To
  // recover the project, the user must reconnect the project.
  DISCONNECTED: 'DISCONNECTED',
});

/**
 * @typedef {typeof PROJECT_STATUS} ProjectStatus
 */

/**
 * The characters that are not allowed in a project name.
 */
export const INVALID_NAME_CHARS_PATTERN = '^[^<>:"|?*]+$';

/**
 * The string used as the value for breadcrumbs when loading.
 */
export const BREADCRUMBS_LOADING_KEY = 'loading';

/**
 * The size map for components still using the old size prop.
 */
export const SIZE_MAP = {
  lg: 'large',
  md: 'medium',
  sm: 'small',
};

/**
 * The categories for the cloud storage items.
 */
export const CLOUD_STORAGE_CATEGORIES = {
  RECENT: /** @type {const} */ ('recent'),
  ALL   : /** @type {const} */ ('all'),
  SHARED: /** @type {const} */ ('shared'),
};

/**
 * The default category for the cloud storage explorer.
 */
export const DEFAULT_CLOUD_STORAGE_CATEGORY = CLOUD_STORAGE_CATEGORIES.ALL;

/**
/**
 * The categories for the cloud storage items as an array.
 */
export const CLOUD_STORAGE_CATEGORY_VALUES = Object.values(CLOUD_STORAGE_CATEGORIES);

/**
 * @typedef {typeof CLOUD_STORAGE_CATEGORIES[keyof typeof CLOUD_STORAGE_CATEGORIES]} CloudStorageCategories
 */
